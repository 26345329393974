<template>
  <div class="left-panel">
    <div class="left-panel__header">
      <ui-button
        class="left-panel__header__back"
        button="secondary"
        icon="chevron_left"
        label=""
        :iconOnly="true"
        @click="back"
      />
      <ui-title v-if="!isLoading" :title="locationName" />
      <skeleton-line v-else height="23px" />
    </div>
    <div class="left-panel__filter">
      <ui-input
        v-if="!isLoading"
        class="left-panel__filter__input"
        v-model="query"
        id="query"
        :label="$t('gmbQaEdit.searchChain.query')"
        design="minimalist"
        icon="search"
        @input="onInput"
      />
      <skeleton-input v-else margin-top="auto" margin-left="16px" margin-right="8px" width="90%" />
      <ui-button
        v-if="!isLoading"
        class="left-panel__filter__button"
        v-bind:class="{ selected: isFiltersDisplayed }"
        button="secondary"
        icon="filter"
        label=""
        :iconOnly="true"
        @click="onClickFilter"
      />
      <skeleton-input v-else width="40px" height="40px" marginTop="auto" marginRight="8px" marginLeft="8px" />
      <transition name="fade">
        <local-post-filters
          v-if="isFiltersDisplayed"
          class="left-panel__filter__window"
          @onClickClose="onClickFilter"
          @sortByChanged="filterSortByChanged"
          @displayOnlyChanged="filterDisplayOnlyChanged"
        />
      </transition>
    </div>
    <ui-data-list
      class="left-panel__list"
      :items="currentLocalPosts"
      :is-loading="isLoading"
      :is-scrolling="isScrolling"
      :no-more-data="pagination.page >= pagination.nbPages"
      @click="selectLocalPostInDataList"
    >
      <template v-slot:item="{ item, index }">
        <post-row
          :index="index"
          :title="item.author.displayName"
          :subtitle="item.text"
          :picture="item.author.profilePhotoUri"
          :options="[
            { label: $t('gmbLocalPosts.left.row.button.viewLive') },
            { label: $t('gmbLocalPosts.left.row.button.edit'), color: '#0078ed' },
            { label: $t('gmbLocalPosts.left.row.button.delete'), color: '#d00' },
          ]"
          @onSelectOption="onSelectRowOption"
        />
      </template>
    </ui-data-list>
    <ui-paginator :isLoading="isLoading" :pagination="pagination" @onClickPaginationButton="onClickPaginationButton" />
  </div>
</template>

<script>
import SkeletonInput from '@/components/Skeleton/Input.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiPaginator from '@/components/UI/Paginator.vue'
import PostRow from '@/components/LocalPosts/PostRow.vue'
import LocalPostFilters from '@/components/LocalPosts/Filters.vue'
import { debounce } from '@/utils/func.util'
import { onScroll } from '@/mixins/scroll.mixin'
export default {
  name: 'LeftPanel',
  components: {
    SkeletonInput,
    SkeletonLine,
    UiInput,
    UiButton,
    UiTitle,
    UiDataList,
    UiPaginator,
    PostRow,
    LocalPostFilters,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    accountId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
    locationName: {
      type: String,
      required: false,
      default: '',
    },
    currentLocalPosts: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: false,
      default: () => ({ page: 1, nbPages: 1 }),
    },
  },
  mixins: [onScroll],
  data() {
    return {
      query: '',
      isUpdating: false,
      isFiltersDisplayed: false,
      selectedRow: null,
    }
  },
  methods: {
    onInput: debounce(
      function (/* value */) {
        this.isUpdating = true
        // TODO: Filter displayed post by search value
        this.isUpdating = false
      },
      300
    ),
    back() {
      this.$router.go(-1)
    },
    onClickFilter() {
      this.isFiltersDisplayed = !this.isFiltersDisplayed
    },
    filterSortByChanged(/* value */) {
      // TODO: Implement this
    },
    filterDisplayOnlyChanged(/* values */) {
      // TODO: Implement this
    },
    selectLocalPostInDataList(data) {
      this.$emit('selectLocalPostInDataList', data)
    },
    async onModalConfirmSave(/* post */) {
      // TODO: implement this.
    },
    onSelectRowOption({ row, optionIndex }) {
      if (optionIndex === 0) {
        // TODO: implement this.
        // this.$emit('onRowClickViewLive', row)
      } else if (optionIndex === 1) {
        this.$emit('onRowClickEditOption', this.currentLocalPosts[row.index])
      } else {
        // TODO: implement this.
        // this.$emit('onRowClickDelete', row)
      }
    },
    onClickPaginationButton(page) {
      this.$emit('onClickPaginationButton', page)
    },
  },
}
</script>

<style lang="scss" scoped>
.left-panel {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $grey-good-table-sep;
  width: 40%;
  overflow-y: auto;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: $screen-md) {
    width: 100%;
  }
  &__header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 16px 16px 8px 16px;
  }
  &__filter {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid $grey-good-table-sep;
    background: $input-color-bg;
    min-height: 64px;
    &__input {
      margin: auto 8px auto 16px;
      width: 100%;
      ::v-deep .ui-input__wrapper__field {
        border: 1px solid $grey-borders;
        background: white;
        height: 40px;
        min-height: 40px;
      }
    }
    ::v-deep > .skeleton-input {
      margin-bottom: auto;
      height: 38px;
    }
    ::v-deep > .ui-button {
      transform: rotate(-90deg);
      border: 1px solid $grey-borders;
      background: white;
    }
    .selected {
      border: none;
      background: $blue-clear-intense;
      color: white;
    }
    &__button {
      margin: auto 8px;
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
    }
    &__window {
      position: absolute;
      top: 70px;
      right: 32px;
      z-index: 2;
    }
  }
  &__list {
    border: 1px solid $grey-good-table-sep;
    border-right: 0;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.left-panel::-webkit-scrollbar {
  display: none;
}
</style>
