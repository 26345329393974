<template>
  <div class="right-panel">
    <div class="right-panel__container">
      <ui-title
        class="right-panel__container__panelTitle"
        :title="$t(`gmbLocalPosts.right.titles.${editingItem == null ? 'create' : 'edit'}`)"
      />
      <ui-button
        v-if="editingItem != null"
        class="right-panel__container__delete"
        button="primary"
        icon="delete"
        label=""
        :iconOnly="true"
        @click="onClickDeletePost"
      />
      <div v-if="editingItem != null" class="right-panel__container__editSubtitle">
        <span>{{ $t('gmbLocalPosts.right.subtitles.edit') }} </span>
        <b>« {{ editingItem.author.displayName }} »</b>
        <span> {{ $t('gmbLocalPosts.right.subtitles.editOn') }} {{ date(editingItem.createTime) }}</span>
      </div>
      <ui-dropdown
        class="right-panel__container__type"
        :value="selectedTypesDropdownValue"
        :options="typesDropdownOptions"
        trackBy="id"
        label="label"
        :noResultsLabel="typesDropdownPlaceholder"
        :placeholder="typesDropdownPlaceholder"
        :isRequired="true"
        :noRadius="true"
        :error="$v.selectedTypesDropdownValue.$error"
        :helper="($v.selectedTypesDropdownValue.$error && $t('errors.required')) || null"
        @input="setTypeDropdownValue"
      >
      </ui-dropdown>
      <div class="right-panel__container__postTitle">
        <span class="right-panel__container__postTitle__label">{{ $t('gmbLocalPosts.right.parts.title.label') }}</span>
        <ui-input
          v-if="!isLoading"
          class="right-panel__container__postTitle__input"
          v-model="titleInputValue"
          id="title"
          :label="$t('gmbLocalPosts.right.parts.title.placeholder')"
          design="minimalist"
          :error="$v.titleInputValue.$error"
          :helper="($v.titleInputValue.$error && $t('errors.required')) || null"
        />
        <span class="right-panel__container__postTitle__example">
          {{ $t('gmbLocalPosts.right.parts.title.example') }}
        </span>
      </div>
      <div class="right-panel__container__date">
        <div class="right-panel__container__date__header">
          <ui-switch
            class="right-panel__container__date__header__switch"
            id="date-range-switch"
            :reverse="true"
            :input-value="dateRangeSwitchValue"
            @input="dateRangeSwichValueChanged"
          />
          <span class="right-panel__container__date__header__label">
            {{ $t('gmbLocalPosts.right.parts.date.label') }}
          </span>
        </div>
        <div class="right-panel__container__date__pickers" v-if="dateRangeSwitchValue">
          <div class="right-panel__container__date__pickers__item">
            <ui-date-picker
              class="right-panel__container__date__pickers__item__picker"
              v-model="startDate"
              :locale="$i18n.locale"
              id="input-start-date"
              design="minimalist"
              label=""
              icon="date_range"
              :minDate="new Date()"
              :reverse="true"
              :error="$v.startDate.$error"
              :helper="($v.startDate.$error && $t('errors.required')) || null"
              @input="onStartDateChanged"
            />
            <span class="right-panel__container__date__pickers__item__label">
              {{ $t('gmbLocalPosts.left.filters.sortBy.options.startDate') }}
            </span>
          </div>
          <div class="right-panel__container__date__pickers__item">
            <ui-date-picker
              class="right-panel__container__date__pickers__item__picker"
              v-model="endDate"
              :locale="$i18n.locale"
              id="input-end-date"
              design="minimalist"
              label=""
              icon="date_range"
              :minDate="startDate"
              :reverse="true"
              :error="$v.endDate.$error"
              :helper="($v.endDate.$error && $t('errors.required')) || null"
            />
            <span class="right-panel__container__date__pickers__item__label">
              {{ $t('gmbLocalPosts.left.filters.sortBy.options.endDate') }}
            </span>
          </div>
        </div>
      </div>
      <div class="right-panel__container__text">
        <span class="right-panel__container__text__label">{{ $t('gmbLocalPosts.right.parts.text.label') }}</span>
        <ui-textarea
          class="right-panel__container__text__area"
          v-model="textareaValue"
          :label="$t('gmbLocalPosts.right.parts.text.placeholder')"
          id="textarea"
          design="minimalist"
          :reverse="true"
          :maxCharactersLength="maxTextareaLength"
          :error="$v.textareaValue.$error"
          :helper="($v.textareaValue.$error && $t('errors.required')) || null"
        />
        <span
          class="right-panel__container__text__helper"
          v-bind:style="{ margin: `${$v.textareaValue.$error ? '-40px' : '-21px'} 8px 8px auto` }"
        >
          {{ textareaCharactersLeft }} {{ $t('gmbLocalPosts.right.parts.text.helper') }}
        </span>
      </div>
      <div class="right-panel__container__medias">
        <div class="right-panel__container__medias__title">
          <span class="right-panel__container__medias__title__label">
            {{ $t('gmbLocalPosts.right.parts.medias.label') }}
          </span>
          <ui-button-tooltip-popover
            :contents="[
              {
                title: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.title'),
                content: [
                  {
                    subtitle: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.format.subtitle'),
                    info: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.format.info'),
                  },
                  {
                    subtitle: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.size.subtitle'),
                    info: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.size.info'),
                  },
                  {
                    subtitle: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.resolution.recommended.subtitle'),
                    info: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.resolution.recommended.info'),
                  },
                  {
                    subtitle: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.resolution.min.subtitle'),
                    info: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.resolution.min.info'),
                  },
                  {
                    subtitle: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.quality.subtitle'),
                    info: $t('gmbLocalPosts.right.parts.medias.tooltip.photos.quality.info'),
                  },
                ],
              },
              {
                title: $t('gmbLocalPosts.right.parts.medias.tooltip.videos.title'),
                content: [
                  {
                    subtitle: $t('gmbLocalPosts.right.parts.medias.tooltip.videos.duration.subtitle'),
                    info: $t('gmbLocalPosts.right.parts.medias.tooltip.videos.duration.info'),
                  },
                  {
                    subtitle: $t('gmbLocalPosts.right.parts.medias.tooltip.videos.size.subtitle'),
                    info: $t('gmbLocalPosts.right.parts.medias.tooltip.videos.size.info'),
                  },
                  {
                    subtitle: $t('gmbLocalPosts.right.parts.medias.tooltip.videos.resolution.subtitle'),
                    info: $t('gmbLocalPosts.right.parts.medias.tooltip.videos.resolution.info'),
                  },
                ],
              },
            ]"
            :options="{ trigger: 'click' }"
          />
        </div>
        <span v-if="$v.medias.$error" class="right-panel__container__medias__error">
          {{ $t('gmbLocalPosts.right.parts.medias.error') }}
        </span>
        <div class="right-panel__container__medias__items">
          <ui-clipper
            class="right-panel__container__medias__items__clipper"
            v-bind:class="{ 'right-panel__container__medias__items__clipper__withMedia': medias.length > 0 }"
            buttonIcon="photo_large"
            acceptFiles="image/*,video/*"
            type="locationPictures"
            :localizedPlaceholder="$t('gmbLocalPosts.right.parts.medias.placeholder')"
            :returnAllFileInfos="true"
            :displayPreview="false"
            @clip="clip"
          />
          <div
            v-for="(media, index) in medias"
            v-bind:key="`picture-${index}`"
            class="right-panel__container__medias__items__media"
          >
            <ui-media-item
              :index="index"
              :media="media"
              @onClickEditMediaOption="onClickEditMediaOption"
              @onClickDeleteMediaOption="onClickDeleteMediaOption"
            />
          </div>
        </div>
        <div class="right-panel__container__medias__buttons">
          <ui-button
            button="primary"
            :label="$t('gmbLocalPosts.right.parts.medias.buttons.cancel')"
            @click="onClickCancelButton"
          />
          <ui-button
            class="right-panel__container__medias__buttons__publish"
            button="primary"
            :label="$t('gmbLocalPosts.right.parts.medias.buttons.publish')"
            @click="onClickPublishButton"
          />
        </div>
      </div>
      <confirm-popover
        v-if="isConfirmPopoverDisplayed"
        class="right-panel__container__confirmPopover"
        :title="$t('gmbLocalPosts.right.confirmPopover.title')"
        @onClickNo="isConfirmPopoverDisplayed = false"
        @onClickYes="onConfirmResponseModalClickYes"
      />
    </div>
    <modal-clipper
      v-if="medias.length > 0 && mediaIndexToUpdate != null"
      name="modal-clipper-2"
      :image="medias[mediaIndexToUpdate].base64 || medias[mediaIndexToUpdate].preview"
      @clip-picture="clipPicture"
      @close="onCloseModalClipper"
    />
  </div>
</template>

<script>
import UiTitle from '@/components/UI/Title'
import UiInput from '@/components/UI/Input.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiSwitch from '@/components/UI/Switch.vue'
import UiTextarea from '@/components/UI/Textarea'
import UiClipper from '@/components/UI/Clipper'
import UiButton from '@/components/UI/Button'
import UiDatePicker from '@/components/UI/DatePicker.vue'
import UiMediaItem from '@/components/UI/MediaItem.vue'
import UiButtonTooltipPopover from '@/components/UI/ButtonTooltipPopover.vue'
import ConfirmPopover from '@/components/UI/ConfirmPopover.vue'
import ModalClipper from '@/components/Modal/Clipper.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { required, requiredIf, maxLength, minLength } from 'vuelidate/lib/validators'
export default {
  name: 'RightPanel',
  components: {
    UiTitle,
    UiInput,
    UiDropdown,
    UiSwitch,
    UiTextarea,
    UiClipper,
    UiButton,
    UiDatePicker,
    UiMediaItem,
    UiButtonTooltipPopover,
    ConfirmPopover,
    ModalClipper,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    editingItem: {
      type: Object,
      required: false,
    },
    account: {
      type: Object,
      required: false,
    },
  },
  mixins: [onScroll],
  data: function () {
    const maxTextareaLength = 150
    return {
      titleInputValue: null,
      textareaValue: null,
      maxTextareaLength,
      textareaCharactersLeft: maxTextareaLength,
      typesDropdownPlaceholder: this.$t('gmbLocalPosts.right.parts.type.placeholder'),
      selectedTypesDropdownValue: null,
      typesDropdownOptions: [
        {
          id: 'standard',
          label: this.$t('gmbLocalPosts.types.standard'),
        },
        {
          id: 'offer',
          label: this.$t('gmbLocalPosts.types.offer'),
        },
        {
          id: 'event',
          label: this.$t('gmbLocalPosts.types.event'),
        },
        {
          id: 'alert',
          label: this.$t('gmbLocalPosts.types.alert'),
        },
      ],
      dateRangeSwitchValue: false,
      currentDate: new Date(),
      startDate: null,
      endDate: null,
      medias: [],
      mediaIndexToUpdate: null,
      isConfirmPopoverDisplayed: false,
    }
  },
  watch: {
    textareaValue(newValue) {
      this.textareaCharactersLeft = this.maxTextareaLength - newValue.length
    },
  },
  methods: {
    date(str) {
      return new Date(str).toLocaleDateString(this.$i18n.locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },
    onClickDeletePost() {
      // TODO: Implement this delete button action (top right button on editing mode)
    },
    setTypeDropdownValue(value) {
      this.selectedTypesDropdownValue = value
    },
    dateRangeSwichValueChanged(value) {
      this.dateRangeSwitchValue = value
      const dateValue = this.dateRangeSwitchValue ? this.currentDate : null
      this.startDate = dateValue
      this.endDate = dateValue
    },
    onStartDateChanged(value) {
      this.endDate = value
    },
    async clip(media) {
      let metadata
      try {
        metadata = media.type.includes('image/')
          ? await this.getImageMetadata(media.file)
          : await this.getVideoMetadata(media.file)
      } catch {
        metadata = null
      }
      this.medias.push({ ...media, metadata })
    },
    async onClickEditMediaOption(mediaIndex) {
      this.mediaIndexToUpdate = mediaIndex
      setTimeout(() => {
        this.$modal.show('modal-clipper-2')
      }, 500)
    },
    onClickDeleteMediaOption(mediaIndex) {
      this.medias = this.medias.filter((_, index) => index !== mediaIndex)
    },
    clipPicture(canvas) {
      this.medias[this.mediaIndexToUpdate].preview = canvas.toDataURL(this.medias[this.mediaIndexToUpdate].type, 0.9)
      this.mediaIndexToUpdate = null
      this.$modal.hide('modal-clipper-2')
    },
    onCloseModalClipper() {
      this.mediaIndexToUpdate = null
    },
    onClickCancelButton() {
      this.$emit('onClickCancelButton')
    },
    onClickPublishButton() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.$v.$reset()
      this.isConfirmPopoverDisplayed = true
    },
    onConfirmResponseModalClickYes() {
      // const data = {
      //   type: this.selectedTypesDropdownValue,
      //   title: this.titleInputValue,
      //   text: this.textareaValue,
      //   ...(this.dateRangeSwitchValue ? { startDate: this.startDate, endDate: this.endDate } : {}),
      //   ...(this.medias.length > 0 ? { medias: this.medias } : {}),
      // }
      // console.log(data)
      // TODO: Implement save post (create or editing depending editingItem == null)
      this.isConfirmPopoverDisplayed = false
    },
    async getImageMetadata(file) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onerror = () => {
          reject(`error loading file: ${file.name}`)
        }
        image.onload = () => {
          const { naturalWidth, naturalHeight } = image
          resolve({ naturalHeight, naturalWidth })
        }
        image.src = URL.createObjectURL(file)
        image.remove()
      })
    },
    async getVideoMetadata(file) {
      return new Promise((resolve, reject) => {
        const videoPlayer = document.createElement('video')
        videoPlayer.setAttribute('src', URL.createObjectURL(file))
        videoPlayer.load()
        videoPlayer.addEventListener('error', ex => {
          reject('error when loading video file', ex)
        })
        videoPlayer.addEventListener('loadedmetadata', () => {
          const { duration, videoHeight, videoWidth } = videoPlayer
          resolve({ duration, videoHeight, videoWidth })
        })
        videoPlayer.remove()
      })
    },
  },
  validations() {
    return {
      selectedTypesDropdownValue: { required },
      titleInputValue: { required },
      textareaValue: {
        required,
        maxLength: maxLength(this.maxTextareaLength),
        minLength: minLength(1),
      },
      startDate: {
        required: requiredIf(() => this.dateRangeSwitchValue),
      },
      endDate: {
        required: requiredIf(() => this.dateRangeSwitchValue),
      },
      medias: {
        $each: {
          validateMedia(media) {
            const { type, file, metadata } = media
            return !(
              (type.includes('image/') &&
                (file.size < 10000 ||
                  file.size > 5000000 ||
                  (metadata != null && (metadata.naturalHeight < 250 || metadata.naturalWidth < 250)))) ||
              (type.includes('video/') &&
                (file.size > 75000000 ||
                  (metadata != null &&
                    (metadata.duration > 30 || metadata.videoHeight < 720 || metadata.videoWidth < 1280))))
            )
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.right-panel {
  position: relative;
  padding: 0 6%;
  width: 60%;
  overflow-y: scroll;

  @media (max-width: $screen-md) {
    width: 100%;
  }
  &__container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    &__panelTitle {
      margin-top: 16px;
      line-height: 28px;
      font-size: 20px;
    }
    &__delete {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      min-width: 32px;
      height: 32px;
      min-height: 32px;
      ::v-deep .ui-button__icon {
        font-size: 16px;
      }
    }
    &__editSubtitle {
      margin-top: 12px;
      border-left: 4px solid $purple-lavender;
      padding-left: 8px;
    }
    &__type {
      margin-top: 24px;
      ::v-deep {
        .multiselect__tags {
          border: 1px solid $grey-borders;
          background-color: white;
        }
        .multiselect__placeholder {
          color: $black-not-black;
          font-size: 13px;
        }
      }
    }
    &__postTitle {
      display: flex;
      flex-direction: column;
      margin-top: 33px;
      &__label {
        z-index: 2;
        margin-left: 8px;
        background-color: white;
        padding: 0 4px;
        width: min-content;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
      }
      &__input {
        margin-top: -8px;
        ::v-deep .ui-input__wrapper__field {
          border: 1px solid $grey-borders;
          border-radius: 6px;
          background-color: white;
        }
      }
      &__example {
        margin: 8px 0 0 13px;
        color: var(--text-color-alt);
        font-size: 11px;
        font-weight: 500;
      }
    }
    &__date {
      display: flex;
      flex-direction: column;
      margin-top: 37px;
      border: 1px solid $grey-borders;
      border-radius: 6px;
      min-height: 64px;
      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px 0;
        &__switch {
          margin-right: 16px;
        }
        &__label {
          font-size: 13px;
          font-weight: 500;
        }
      }
      &__pickers {
        display: flex;
        position: relative;
        justify-content: space-evenly;
        margin-bottom: 24px;
        &__item {
          max-width: 160px;
          &__label {
            position: absolute;
            bottom: 36px;
            margin-left: 8px;
            background-color: white;
            padding: 0 4px;
            width: min-content;
            text-align: center;
            white-space: pre;
            font-size: 11px;
            font-weight: 500;
          }
          &__picker {
            ::v-deep {
              .ui-date-picker__wrapper__field {
                border: 1px solid $grey-borders;
                border-radius: 4px;
                background-color: white;
              }
              .ui-date-picker__wrapper__icon--right {
                color: $dark-grey;
              }
            }
          }
        }
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      margin-top: 33px;
      &__label {
        z-index: 2;
        margin-left: 8px;
        background-color: white;
        padding: 0 4px;
        width: min-content;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
      }
      &__area {
        margin-top: -8px;
        ::v-deep #textarea {
          border: 1px solid $grey-borders;
          border-radius: 6px;
          background-color: white;
        }
      }
      &__helper {
        z-index: 2;
        color: $grey-text;
        font-size: 11px;
      }
    }
    &__medias {
      display: flex;
      flex-direction: column;
      margin: 32px 0;
      &__title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        &__label {
          font-size: 13px;
          font-weight: 500;
        }
      }
      &__error {
        margin: -8px 0 16px 0;
        padding-left: 16px;
        color: map-get($generic-color-variants, 'error');
        font-size: 12px;
      }
      &__items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        > * {
          margin-bottom: 24px;
        }
        &__clipper {
          flex-grow: 2;
          &__withMedia {
            margin-right: 24px;
          }
          ::v-deep {
            .ui-clipper__placeholder {
              border: 1px dashed $blue-light;
              border-radius: 6px;
              background-color: $white-not-white;
              height: 138px;
            }
            .ui-button {
              border: none;
            }
            .ui-button__icon {
              background: linear-gradient(to right, $blue-royal, $blue-clear-intense);
              -webkit-background-clip: text;
              font-size: 32px;
              -webkit-text-fill-color: transparent;
            }
            .ui-clipper__placeholder__caption {
              max-width: 192px;
              text-align: center;
              color: $blue-intense;
              font-size: 13px;
            }
          }
        }
        &__media {
          display: flex;
          flex-direction: column;
          margin-right: 24px;
          height: 138px;
        }
      }
      &__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 9px;
        &__publish {
          border: 0;
          background: linear-gradient(to right, $blue-royal, $blue-clear-intense);
          color: white;
        }
      }
    }
    &__confirmPopover {
      ::v-deep .confirm-popover__blur {
        margin-left: -6%;
        width: 112%;
      }
    }
  }
}
</style>
