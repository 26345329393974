<template>
  <div class="post-row">
    <div class="post-row__content">
      <img class="post-row__content__picture" :src="picture" alt="post-picture" />
      <div class="post-row__content__status" />
      <div class="post-row__content__wrapper">
        <span class="post-row__content__wrapper__title">{{ title }}</span>
        <span class="post-row__content__wrapper__subtitle">{{ subtitle }}</span>
      </div>
      <ui-button
        class="post-row__content__optionsButton"
        button="secondary"
        icon="more_vert"
        label=""
        :iconOnly="true"
        @click="onClickOptions"
      />
      <ui-options-popover :options="options" :displayed="displayOptionsPopup" @onClickOption="onSelectOption" />
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiOptionsPopover from '@/components/UI/OptionsPopover.vue'
export default {
  name: 'PostRow',
  components: {
    UiButton,
    UiOptionsPopover,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data: () => ({
    displayOptionsPopup: false,
  }),
  methods: {
    onClickOptions(event) {
      event.stopPropagation()
      this.displayOptionsPopup = !this.displayOptionsPopup
    },
    onSelectOption(index) {
      const { index: rowIndex, title, subtitle, picture } = this
      this.$emit('onSelectOption', {
        row: {
          index: rowIndex,
          title,
          subtitle,
          picture,
        },
        optionIndex: index,
      })
      this.displayOptionsPopup = !this.displayOptionsPopup
    },
  },
}
</script>

<style lang="scss" scoped>
.post-row {
  padding: 20px 8px 20px 24px;
  width: 100%;
  &__content {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    &__picture {
      width: 48px;
      height: auto;
    }
    &__status {
      position: relative;
      right: 10px;
      bottom: 14px;
      border: 1px solid white;
      border-radius: 6px;
      background: $purple-lavender; // $blue-clear-intense | $blue-intense | #dd0000
      width: 12px;
      height: 12px;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      &__title {
        font-size: 13px;
        font-weight: 600;
      }
      &__subtitle {
        font-size: 12px;
      }
    }
    &__optionsButton {
      margin: auto 0 auto auto;
      max-width: 32px;
      max-height: 32px;
    }
  }
}
</style>
