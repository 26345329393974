<template>
  <div class="ui-button-tooltip-popover">
    <v-popover placement="top" offset="3" popoverBaseClass="ui-button-tooltip ui-button-popover" v-bind="options">
      <ui-button class="ui-button-tooltip-popover__button" button="secondary" icon="info" label="" :iconOnly="true" />
      <template slot="popover">
        <div class="ui-button-tooltip-popover__content">
          <div
            class="ui-button-tooltip-popover__content__part"
            v-for="({ title, content }, idx) in contents"
            v-bind:key="`${idx}-${title}`"
          >
            <ui-title class="ui-button-tooltip-popover__content__title" :title="title" />
            <p v-for="({ subtitle, info }, idx) in content" v-bind:key="`${idx}-${subtitle}`">
              <b>{{ subtitle }}</b>
              {{ info }}
            </p>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import UiTitle from '@/components/UI/Title'
import UiButton from '@/components/UI/Button'
export default {
  name: 'UiButtonTooltipPopover',
  components: {
    UiTitle,
    UiButton,
  },
  props: {
    contents: {
      type: Array,
      required: true,
      // Eg. [{ title: 'Top part', parts: [{ subtitle: 'left bold line part', info: 'right line part' }] }]
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
</script>

<style lang="scss">
.ui-button-tooltip {
  @include tooltip;
  &.ui-button-popover {
    @include tooltip-popover;
    .popover-arrow {
      display: block;
      left: calc(50% - 5px) !important;
    }
  }

  @include tooltip-options;

  max-width: 40%;
}
</style>

<style lang="scss" scoped>
.ui-button-tooltip-popover {
  &__button {
    margin-left: 8px;
    max-width: 24px;
    max-height: 24px;
    color: $blue-light;
  }
  &__content {
    display: flex;
    justify-content: space-evenly;
    background-color: $tooltip-color-bg;
    &__part {
      padding: 24px 0;
      max-width: 40%;
      &__title {
        margin-top: 8px;
      }
    }
  }
}
</style>
