<template>
  <div class="ui-media-item">
    <div class="ui-media-item__wrapper">
      <img
        v-if="isImage"
        class="ui-media-item__wrapper__image"
        :src="media.preview || media.base64"
        :alt="`media-${index}`"
      />
      <video v-else class="ui-media-item__wrapper__video" preload controls>
        <source :src="media.preview || media.base64" :type="media.type" />
        <source :src="media.preview || media.base64" type="video/mp4" />
        <source :src="media.preview || media.base64" type="video/ogg" />
        <source :src="media.preview || media.base64" type="video/webm" />
      </video>
      <ui-button
        class="ui-media-item__wrapper__options"
        button="secondary"
        icon="more_vert"
        label=""
        :iconOnly="true"
        @click="onClickToggleOptionsButton"
      />
      <ui-options-popover :options="options" :displayed="isOptionsPopupDisplayed" @onClickOption="onSelectOption" />
    </div>
    <span class="ui-media-item__filename">
      {{ media.file.name }}
    </span>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiOptionsPopover from '@/components/UI/OptionsPopover.vue'
export default {
  name: 'UiMediaItem',
  components: {
    UiButton,
    UiOptionsPopover,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    media: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      isOptionsPopupDisplayed: false,
      options: [
        { label: this.$t('gmbLocalPosts.left.row.button.edit'), color: '#0078ed' },
        { label: this.$t('gmbLocalPosts.left.row.button.delete'), color: '#d00' },
      ],
      cover: null,
    }
  },
  computed: {
    isVideo() {
      return this.media.type.includes('video/')
    },
    isImage() {
      return this.media.type.includes('image/')
    },
  },
  async created() {
    if (this.isVideo) {
      this.options = this.options.slice(1)
    }
  },
  methods: {
    onClickToggleOptionsButton() {
      this.isOptionsPopupDisplayed = !this.isOptionsPopupDisplayed
    },
    onSelectOption(optionIndex) {
      this.$emit(optionIndex === 0 && !this.isVideo ? 'onClickEditMediaOption' : 'onClickDeleteMediaOption', this.index)
      this.isOptionsPopupDisplayed = !this.isOptionsPopupDisplayed
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-media-item {
  position: relative;
  width: min-content;
  &__wrapper {
    display: flex;
    &__options {
      margin-top: -14px;
      width: 24px;
      min-width: 24px;
    }
    &__image {
      border-radius: 4px;
      width: auto;
      max-height: 118px;
    }
    &__video {
      width: auto;
      max-height: 118px;
    }
  }
  &__filename {
    margin-top: 4px;
    color: #9696a4;
    font-size: 11px;
  }
}
</style>
