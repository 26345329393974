<template>
  <div class="gmb-local-posts" @scroll.passive="onScroll($event.srcElement, $refs.fixedElement.$el)">
    <screen-header
      class="gmb-local-posts__header"
      :title="$t('gmbLocalPosts.title')"
      :breadcrumbs="breadcrumbs"
      :right-content="true"
      ref="fixedElement"
    >
      <skeleton-icon v-if="isLoading" margin-left="16px" width="44px" height="44px" />
    </screen-header>
    <screen-container class="gmb-local-posts__container" :full-height="true">
      <screen-grid :full-height="true">
        <screen-card class="gmb-local-posts__container__card" :full-height="true" :noPadding="true">
          <template v-slot:body>
            <div class="gmb-local-posts__container__card__content">
              <left-panel
                v-if="!isMobileOrTablet || (isMobileOrTablet && currentLocalPostsDetail == null)"
                :isLoading="isLoading || isLoadingLocationAccount"
                :isScrolling="isScrolling"
                :locationName="currentLocation.locationName"
                :currentLocalPosts="currentLocalPosts"
                :pagination="currentLocalPostsPagination"
                :accountId="currentAccountName"
                :locationId="currentLocationName"
                @selectLocalPostInDataList="selectPostInDataList"
                @onRowClickEditOption="onClickEditItem"
                @onClickPaginationButton="onClickLeftPaginationButton"
              />
              <right-panel
                v-if="!isMobileOrTablet || (isMobileOrTablet && currentLocalPostsDetail != null)"
                :isLoading="isLoadingDetail"
                :isScrolling="isScrolling"
                :editingItem="editingItem"
                :account="{
                  clientLogo: currentClient.logo,
                  locationName: currentLocation.locationName,
                  role: 'MERCHANT',
                }"
                @onClickCancelButton="onClickCancelButton"
              />
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { isMobile, isTablet } from '@/utils/navigator.util'
import { mapActions, mapState } from 'vuex'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import LeftPanel from '@/components/LocalPosts/LeftPanel.vue'
import RightPanel from '@/components/LocalPosts/RightPanel.vue'
import { onScroll } from '@/mixins/scroll.mixin'
export default {
  name: 'GmbLocalPosts',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonIcon,
    LeftPanel,
    RightPanel,
  },
  mixins: [onScroll],
  data() {
    return {
      isLoading: false,
      isLoadingLocationAccount: false,
      isLoadingDetail: false,
      isScrolling: false,
      editingItem: null,
      isMobile: isMobile(),
      isTablet: isTablet(),
    }
  },
  async created() {
    this.isLoadingLocationAccount = true
    await Promise.all([
      this.loadLocation({
        locationName: this.currentLocationName,
        currentAccountName: this.currentAccountName,
      }),
      this.getAccounts({}),
    ])
    this.isLoadingLocationAccount = false
    if (!this.$route.params.name) {
      await this.$router.replace({
        ...this.$route,
        params: { ...this.$route.params, name: this.accounts[0].name },
      })
    }
  },
  watch: {
    '$route.params.name': {
      immediate: true,
      async handler() {
        if (this.$route.params.name) {
          await this.getGmbLocalPosts()
        }
      },
    },
  },
  computed: {
    ...mapState({
      accounts: state => state.gmb.accounts,
      currentLocation: state => state.gmb.currentLocation,
      currentLocalPosts: state => state.gmb.currentLocalPosts?.data || [],
      currentLocalPostsPagination: state => state.gmb.currentLocalPosts?.pagination || { page: 1, nbPages: 1 },
      currentLocalPostsDetail: state => state.gmb.currentLocalPostsDetail,
      currentClient: state => state.client.currentClient,
    }),
    isMobileOrTablet() {
      return this.isMobile && this.isTablet
    },
    currentLocationName() {
      return this.$route.params.name
    },
    currentAccountName() {
      return this.$route.params.name.split('/locations')[0]
    },
    currentAccount() {
      return this.accounts.find(account => account.name === this.currentAccountName)
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('gmbQa.breadcrumb'),
          route: {
            name: 'GmbQa',
            params: {
              name: this.currentAccountName,
            },
          },
        },
        {
          label: this.$t('gmbLocalPosts.breadcrumb'),
          route: {
            name: 'GmbLocalPosts',
            params: {
              name: this.currentAccountName,
            },
          },
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getLocalPosts: 'gmb/getLocalPosts',
      getLocalPostsDetail: 'gmb/getLocalPostsDetail',
      getAccounts: 'gmb/getAccounts',
      loadLocation: 'gmb/loadLocation',
    }),
    async getGmbLocalPosts(page = null) {
      this.isLoading = true
      try {
        await this.getLocalPosts({
          account: this.currentAccountName,
          location: this.currentLocationName,
          page: page || 1,
        })
      } finally {
        this.isLoading = false
      }
    },
    async selectPostInDataList(data) {
      this.isLoadingDetail = true
      try {
        await this.getLocalPostsDetail({
          account: this.currentAccountName,
          location: data.location,
          id: data.name,
        })
      } finally {
        this.isLoadingDetail = false
      }
    },
    onClickEditItem(row) {
      this.editingItem = row
    },
    onClickCancelButton() {
      this.editingItem = null
    },
    async onClickLeftPaginationButton(page = null) {
      await this.getGmbLocalPosts(page)
    },
  },
}
</script>

<style lang="scss" scoped>
.gmb-local-posts {
  ::v-deep.ui-data-list__item {
    padding: 0;
  }
  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }
  }
  &__container {
    height: 100%;
    &__card {
      height: 100%;
      &__content {
        display: flex;
        justify-content: flex-start;
        height: 100%;
      }
    }
  }
}
</style>
