<template>
  <transition :name="transitionName">
    <div v-if="displayed" class="options-popover">
      <ui-button
        v-for="({ label, color }, index) in options"
        v-bind:key="`${label}-${index}`"
        v-bind:style="{ color: color || 'black' }"
        class="options-popover__button"
        button="secondary"
        :label="label"
        @click="onClickOption($event, index)"
      />
    </div>
  </transition>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
export default {
  name: 'UiOptionsPopover',
  components: {
    UiButton,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    displayed: {
      type: Boolean,
      required: true,
    },
    transitionName: {
      type: String,
      required: false,
      default: 'fade',
    },
  },
  methods: {
    onClickOption(event, index) {
      event.stopPropagation()
      this.$emit('onClickOption', index)
    },
  },
}
</script>

<style lang="scss" scoped>
.options-popover {
  display: flex;
  position: absolute;
  top: -8px;
  right: 24px;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 2;
  border: 1px solid $grey-good-table-sep;
  border-radius: 12px;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
  background: white;
  min-width: 100px;
  font-size: 12px;
}
</style>
