<template>
  <div class="local-post-filters">
    <div class="local-post-filters__header">
      <span class="local-post-filters__header__title">{{ $t('gmbLocalPosts.left.filters.title') }}</span>
      <ui-button
        class="local-post-filters__header__close"
        button="secondary"
        icon="close"
        label=""
        :iconOnly="true"
        @click="onClickClose"
      />
    </div>
    <div class="local-post-filters__separator" />
    <div class="local-post-filters__sort-by">
      <span class="local-post-filters__sort-by__label">{{ $t('gmbLocalPosts.left.filters.sortBy.label') }}</span>
      <ui-dropdown
        class="local-post-filters__sort-by__dropdown"
        :value="selectedDropdownValue"
        :options="dropdownOptions"
        track-by="id"
        label="label"
        :noResultsLabel="dropdownOptions[0].label"
        :placeholder="$t('gmbLocalPosts.left.filters.sortBy.placeholder')"
        :no-radius="true"
        @input="setDropdownValue"
      />
    </div>
    <div class="local-post-filters__display">
      <span class="local-post-filters__display__label">{{ $t('gmbLocalPosts.left.filters.displayOnly.label') }}</span>
      <div class="local-post-filters__display__buttons">
        <ui-button
          v-for="{ label, icon, selected } in displayOnlyButtons"
          v-bind:key="label"
          class="local-post-filters__display__buttons__item"
          v-bind:class="{ selected }"
          button="cta"
          :icon="icon"
          :label="$t(label)"
          @click="onClickDisplayOnlyButton(label)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
export default {
  name: 'LocalPostFilters',
  components: {
    UiButton,
    UiDropdown,
  },
  data: function () {
    const defaultDropdownOption = {
      id: 'endDate',
      label: this.$t('gmbLocalPosts.left.filters.sortBy.options.endDate'),
    }
    return {
      dropdownOptions: [
        defaultDropdownOption,
        {
          id: 'startDate',
          label: this.$t('gmbLocalPosts.left.filters.sortBy.options.startDate'),
        },
      ],
      selectedDropdownValue: defaultDropdownOption,
      displayOnlyButtons: [
        {
          label: 'gmbLocalPosts.types.standard',
          icon: 'zone',
          selected: false,
        },
        {
          label: 'gmbLocalPosts.types.offer',
          icon: 'attribut',
          selected: false,
        },
        {
          label: 'gmbLocalPosts.types.event',
          icon: 'date',
          selected: false,
        },
        {
          label: 'gmbLocalPosts.types.alert',
          icon: 'notifications',
          selected: false,
        },
      ],
    }
  },
  methods: {
    onClickClose() {
      this.$emit('onClickClose')
    },
    onClickDisplayOnlyButton(label) {
      this.displayOnlyButtons = this.displayOnlyButtons.map(button =>
        button.label === label ? { ...button, selected: !button.selected } : button
      )
      this.$emit(
        'displayOnlyChanged',
        this.displayOnlyButtons.map(button => ({ ...button, id: button.label.split('.')[2] }))
      )
    },
    setDropdownValue(value) {
      this.selectedDropdownValue = value
      this.$emit('sortByChanged', this.selectedDropdownValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.local-post-filters {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey-borders;
  border-radius: 12px;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.16);
  background: white;
  width: 80%;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 24px;
    height: 44px;
    &__title {
      font-size: 13px;
      font-weight: 600;
    }
    &__close {
      border: 1px solid $grey-borders;
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
    }
    ::v-deep > .ui-button {
      background: $grey-borders;
      > .ui-button__icon {
        font-size: 16px;
      }
    }
  }
  &__separator {
    background-color: $grey-borders;
    width: 100%;
    height: 1px;
  }
  &__sort-by {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding: 0 24px 0 24px;
    &__label {
      margin-right: 13px;
      white-space: pre;
      font-size: 13px;
      font-weight: 500;
    }
    &__dropdown {
      margin-right: 8px;
      ::v-deep {
        .multiselect__tags {
          border: 1px solid $grey-borders;
          background-color: white;
        }
        .multiselect__placeholder {
          color: $black-not-black;
          font-size: 13px;
        }
      }
    }
  }
  &__display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 24px;
    padding: 0 16px 0 24px;
    &__label {
      font-size: 13px;
      font-weight: 500;
    }
    &__buttons {
      margin: 8px 0 16px 0;
      &__item {
        margin: 4px;
        width: 47%;
      }
      ::v-deep > .ui-button {
        justify-content: flex-start;
        border: none;
        border-radius: 4px;
        box-shadow: none;
        background: $grey-very-light;
        > .ui-button__icon {
          margin: 0 8px 0 16px;
        }
        &:nth-child(1) > .ui-button__icon {
          color: $purple-lavender;
        }
        &:nth-child(2) > .ui-button__icon {
          color: $blue-intense;
        }
        &:nth-child(3) > .ui-button__icon {
          color: orange;
        }
        &:nth-child(4) > .ui-button__icon {
          color: $red-intense;
        }
        &--text-icon {
          padding: 0;
          font-size: 12px;
          font-weight: 500;
        }
        &.selected {
          background: $blue-clear-intense;
          color: white;
          > .ui-button__icon {
            color: white;
          }
        }
      }
    }
  }
}
</style>
